.adminHistoryDidGenerations {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--background-bg-light, #192033);
  padding: 24px 16px 24px 16px;
  gap: 24px;
  border-radius: 16px;
  margin-bottom: 24px;
  margin-top: 24px;
  &.showFull {
    height: 100%;
  }

  .historyDidGenerationsHeader {
    display: flex;
    //styleName: Title/Text - Title 3;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.94px;
    text-align: left;
    height: 40px;
    margin: 24px;
    width: 98%;
    justify-content: space-between;
    // button {
    //   color: var(--Button-button-active, #7479f6);
    //   border-color: var(--Button-button-active, #7479f6);
    //   background-color: transparent;
    //   :hover {
    //     color: var(--Button-button-hover, #9a9df8);
    //     border: var(--Button-button-hover, #9a9df8);
    //     background-color: transparent;
    //   }
    // }
  }

  .historyDidGenerationsFilters {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
    .historyDidGenerationsFiltersSelector{
      display: flex;
      width: 300px;
      flex-direction: column;
    }
    .historyDidGenerationsFiltersDatePicker {
      display: flex;
      flex-direction: column;
    }
    .historyDidGenerationsFiltersCheckboxes {
      display: flex;
      gap: 24px;
      align-items: flex-end;
      height: 100%;
    }
    &.dark{
      background: var(--background-bg-light, #192033);
      .ant-modal-content{
        background: var(--background-bg-light, #192033);
      }
      .ant-modal-header{
        background: var(--background-bg-light, #192033);
      }
      .supportCreateRequest{
        background: var(--background-bg-light, #192033);
        .ant-form-item-label{
          color: var(--Text-text-placeholder, #979AA4);
        }
      }
    }
    &.light{
      background: var(--background-bg-pages, #F8F8FA);
      .ant-modal-content{
        background: var(--background-bg-pages, #F8F8FA);
      }
      .ant-modal-header{
        background: var(--background-bg-pages, #F8F8FA);
      }
      .supportCreateRequest{
        background: var(--background-bg-pages, #F8F8FA);
        .ant-form-item-label{
          color: var(--Text-text-placeholder, #979AA4);
  
        }
      }
    }
  }
}

.historyDidGenerationsTable {
  &.light {
  }
  &.dark {
  }

  .historyDidGenerationsTableNeuronet {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .historyDidGenerationsTableOperations {
    display: flex;
    flex-direction: row;
    .tableOperation {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 32px;
      &:nth-child(2) {
        border-left: 2px solid var(--Border-border-light, #6F748380);
      }
    }
  }
}
