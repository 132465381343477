.menu {
  display: flex;
  width: 100%;
  flex-direction: column;

  .menuItem {
    display: flex;
    width: calc(100% - 8px);
    padding: 12px;
    margin: 8px 0px 8px 8px;
    position: relative;
    align-items: center;
    transition: all 0.15s ease-in;
    &.collapsed {
      justify-content: center;
    }
    cursor: pointer;
    .menuicon{
      display: flex;
      align-items: center;
    }
    .menutitle{
      margin-left: 12px;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 500;
      line-height: 14.63px;
      text-align: left;
      color: #121724;
      overflow: hidden;
      text-wrap: nowrap;
      width: 220px;
      // transition: all 0.15s linear;

    }
    &.selected {
      border-radius: 6px 0px 0px 6px;
      .menutitle{
      //styleName: Body/Body text SemiBold 12;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 600;
      line-height: 14.63px;
      text-align: left;
      }
    }
    .selectedMark{
      width: 4px;
      position: absolute;
      // background-color: #7479F6;
      right: 0px;
      top: 0px;
      height: 100%;
    }
  }
  &.light{
    .menuItem {

      .menutitle{
        color: #121724;
      }
      &.selected{
        background-color: var(--background-bg-active, #EAEBFD);
      }
      &:hover{
        background-color: var(--Button-button-default, #232E48);
      }
      .selectedMark{
        background-color: var(--Icon-icon-normal, #7479F6);

      }
    }
  }
  &.dark{

    .menuItem {
      .menutitle{
        color: #bdc0c7;
      }
      &.selected{
        background-color: var(--background-bg-active, #EAEBFD);
      }
      &:hover{
        background-color: var(--Button-button-default, #F3F3F7);
      }
      .selectedMark{
        background-color: var(--Icon-icon-normal, #7479F6);

      }
    }
  }

  &.collapsed {
    .menuItem {
      .menutitle{
        // visibility: hidden;
        // display: none;
        width: 0px;
      }
    }
  }
}