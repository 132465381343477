.adminHistoryImageGenerations{
  // margin: 32px 24px 32px 12px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--background-bg-light, #FEFEFE);
  padding: 24px 16px 24px 16px;
  gap: 24px;
  border-radius: 16px;

  
  .historyImageGenerationsHeader{
    display: flex;
    //styleName: Title/Text - Title 3;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.94px;
    text-align: left;
    height: 40px;
    margin: 24px;
    justify-content: space-between;
    // button{
    //   color: var(--Button-button-active, #7479F6);
    //   border-color: var(--Button-button-active, #7479F6);
    //   background-color: transparent;
    //   :hover{
    //     color: var(--Button-button-hover, #9A9DF8);
    //     border: var(--Button-button-hover, #9A9DF8);
    //     background-color: transparent;

    //   }

    // }
  }
  
      .historyImageGenerationsFilters {
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: center;
        .historyImageGenerationsFiltersSelector{
          display: flex;
          width: 300px;
          flex-direction: column;
        }
        .historyImageGenerationsFiltersDatePicker {
          display: flex;
          flex-direction: column;
        }
        .historyImageGenerationsFiltersUsers{
          display: flex;
          flex-direction: column;
        }
        .historyImageGenerationsFiltersCheckboxes {
          display: flex;
          gap: 24px;
          align-items: flex-end;
          height: 54px;
        }
        &.dark{
          background: var(--background-bg-light, #192033);
          .ant-modal-content{
            background: var(--background-bg-light, #192033);
          }
          .ant-modal-header{
            background: var(--background-bg-light, #192033);
          }
          .supportCreateRequest{
            background: var(--background-bg-light, #192033);
            .ant-form-item-label{
              color: var(--Text-text-placeholder, #979AA4);
            }
          }
        }
        &.light{
          background: var(--background-bg-pages, #F8F8FA);
          .ant-modal-content{
            background: var(--background-bg-pages, #F8F8FA);
          }
          .ant-modal-header{
            background: var(--background-bg-pages, #F8F8FA);
          }
          .supportCreateRequest{
            background: var(--background-bg-pages, #F8F8FA);
            .ant-form-item-label{
              color: var(--Text-text-placeholder, #979AA4);
      
            }
          }
        }
      }
  .chatMessageImageContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    // background: var(--background-bg-light, #FEFEFE);
  
    
  }
}
