.avatarContainer{
  align-items: center ;
  justify-content: flex-end;
  display: flex;
  height: 100%;
  // span{
  //     align-items: center;
  //     color: #fff;
  //     padding-left: 1rem;
  // }
  .username_container{
    color: var(--Text-text-black, #FDFDFD);
    //styleName: Body/Body text SemiBold 12;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.63px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    margin-left: 12px;
    margin-right: 24px;
    display: flex;
    flex-direction: column;
  }  
}