.lkChatText {
  display: flex;
  flex-direction: column;
  height: 100%;

  .lkChatTextHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    .lkChatTextHeaderText {
      //styleName: Title/Text - Title 3;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 600;
      line-height: 21.94px;
      text-align: left;
    }
  }

  .lkChatTextContent {
    display: flex;
    flex-direction: row;
    gap: 12px;
    height: calc(100% - 32px);
    .lkChatTextGenerationHistory {
      display: flex;
      width: 33%;
    }
    .lkChatTextChat {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      width: 66%;
      .messageList {
        padding: 24px;
        border: 1px solid var(--Border-border-light);
        border-radius: 16px;
        background: var(--background-bg-light, #fefefe);
      // border: none;
        margin-bottom: 24px;
        height: 100%;
        max-height: calc(100% - 121px);
        overflow-y: auto;
      }
    }
  }
}
