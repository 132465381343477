.loader{
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

}