.messageReplyContainer{
  display: flex;
  align-self: flex-end;
  width: 100%;
  .messageReply{
    display: flex;
    flex-direction: row;
    max-width: 90%;
    background: var(--background-bg-active, #EAEBFD);
    padding: 24px;
    border-radius: 16px;



    .avatarContainer{
      display: flex;
      align-items: flex-start;
      margin-right: 16px;
    }
    .messasgeBodyContainer{
      display: flex;
      .messageBody{
        display: flex;
        flex-direction: column;
        .dateName{
          display: flex;
          gap: 12px;
        }
        .messageText{
          display: flex;
          padding-top: 4px;
          //styleName: Body/Body text medium 14;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          text-align: left;
          color: var(--Text-text-black, #121724);
          white-space: pre-line;
          overflow-wrap: anywhere;//
        }
      }
    }
  }
}
