.chatAudioTextCard {
    display: flex;
    flex-direction: column;
    height: 217px;
    width: 236px;
    border: 1px solid #dbdbe080;
    border-radius: 18px;
    overflow: hidden;
    align-items: center;
    justify-content: flex-start;
    // .chatAudioTextCardImage {
    //   display: flex;
    //   width: 236px;
    //   height: 200px;
    //   overflow: hidden;
    //   cursor: pointer;
    //   .imageLoader{
    //     display: flex;
    //     width: 100%;
    //     height: 100%;
    //     justify-content: center;
    //     align-items: center;
    //   }

    // }
    .chatAudioTextCardTitle {
      display: -webkit-box;
      align-self: center;
      //styleName: Body/Body text medium 12;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
      line-height: 17.07px;
      text-align: left;
      overflow: hidden;
      line-clamp: 1;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      margin: 8px 8px 0px 8px;
      height: 33px;
      color: var(--Text-text-black, #121724);
      span:nth-child(2) {
        margin-left: 12px;
      }
    }
    .chatAudioTextCardText {
      //styleName: Body/Body text medium 14;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      text-align: left;
      color: var(--Text-text-black, #FDFDFD);
      padding: 0px 8px 0px 8px;
      margin-bottom: 8px;
      height: 126px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 6;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow-wrap: anywhere;
    }
    .chatAudioTextCardIcons {
      display: flex;
      height: 42px;
      width: 100%;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: row;
      // border-top: 1px solid #dbdbe080;
      border-top: 1px solid var(--Border-border-light, #DBDBE080);

      .chatAudioTextCardIconBtn {
        background: var(--background-bg-light, #192033);
        display: flex;
        width: 58px;
        height: 100%;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .chatAudioTextCardIconBtnGap {
        width: 1px;
        height: 26px;
        background: var(--Border-border-light, #6F748380);
      }
    }
  }