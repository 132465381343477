.generationHistory{
  display: flex;
  padding: 24px;
  flex-direction: column;
  background: var(--background-bg-light, #FEFEFE);
  border-radius: 16px;
  margin-top: 24px;
width: 100%;
  .generationHistoryHeader {
    display: flex;
    justify-content: space-between;
    height: 40px;
    width: 100%;
    .generationHistoryHeaderTitle{
      display: flex;
      //styleName: Title/Text - Title 3;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 600;
      line-height: 21.94px;
      text-align: left;
      align-items: center;
    }
    .generationHistoryHeaderIcon{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      gap: 10px;
      border-radius: 50%;
      border: 2px solid var(--Icon-icon-normal, #7479F6);
      cursor: pointer;
    }
  }

  .generationHistoryMessages{
    display: flex;
    width: 100%;
    flex-direction: column;
    cursor: pointer;
    overflow: hidden;
    overflow-y: auto;
    scrollbar-width: 4px;
    
    .generationHistoryMessage{
      display: flex;
      width: 100%;
      height: 64px;
      justify-content: flex-start;
      border-bottom: 2px solid var(--Border-border-light, #DBDBE080);
      margin-top: 24px;

      .generationHistoryMessageIcon{
        display: flex;
        width: 36px;
        height: 36px;
        align-items: center;
        justify-content: center;
        svg{
          fill: var(--Icon-icon-disabled, #C4C4C8);
        }
      }
      .generationHistoryMessageContainer{
        display: flex;
        width: 100%;
        height: 40px;
        flex-direction: column;        

        .generationHistoryMessageTitle{
          display: flex;
          width: 100%;
          //styleName: Body/Body text medium 14;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          text-align: left;
          color: var(--Text-text-black, #121724);
          height: 64px;
          overflow: hidden;
        }
        .generationHistoryMessageDate{
          display: flex;
          width: 100%;
          //styleName: Body/Body text medium 12;
          font-family: Montserrat;
          font-size: 12px;
          font-weight: 500;
          line-height: 14.63px;
          text-align: left;
          color: var(--Text-text-placeholder, #979AA4);
        }
      }
    }
  }
}