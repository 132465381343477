.faqListItem {
  display: flex;
  gap: 12px;
  .faqListItemLink {
    &.deleted {
      text-decoration-line: line-through;
      color: var(--Text-text-error, #DF7171);
    }
    &.published{
      font-weight: 500;
      color: var(--Text-text-success, #009E39);
    }
  }
}