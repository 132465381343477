.didVideoCard {
  display: flex;
  flex-direction: column;
  // width: 273px;
  // height: 264px;
  height: 263px;
  width: 236px;
  // gap: 10px;
  // padding-top: 8px;
  // padding-right: 12px;
  // padding-bottom: 12px;
  // padding-left: 12px;
  border-radius: 6px;
  border-width: 1px;
  justify-content: space-between;
  background: var(--background-bg-light, #192033);
  border: 1px solid var(--Border-border-light, #6f748380);
  .didVideoCardContent {
    display: flex;
    // width: 249;
    // width: 230px;я
    min-height: 220px;
    border-radius: 4.22px;
    align-items: center;
    justify-content: center;
    background-color: var(--background-bg-light);
    .imageLoader {
      display: flex;
    }
    .didSuccessCard {
      display: flex;
      position: relative;
      
      // height: 100%;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      .didSuccessCardBackground{
        display: flex;
        position: absolute;
        top: 0px;
        // left: 0px;
        width: 100%;
        height: 200px;
        z-index: 0;
        border-radius: 4.22px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        
      }
      img {
        height: 200px;
        z-index: 1;
      }
      .didSuccessCardPlay {
        display: flex;
        position: absolute;
        z-index: 2;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        .didSuccessCardPlayButton {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background: var(--background-bg-hint, #1e283ecc);
          border: 0.79px solid var(--Border-border-light, #6f748380);
          cursor: pointer;
          &.disabled {
            cursor: default;
            svg {
              path {
                fill: var(--Button-button-disabled);
              }
            }
          }
        }
      }
      .didSuccessCardTitle {
        // width: 100%;
        // height: 22px;
        display: -webkit-box;
        align-self: start;
        //styleName: Body/Body text medium 12;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        line-height: 14.63px;
        text-align: left;
        overflow: hidden;
        line-clamp: 1;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        margin: 8px;
        color: var(--Text-text-black, #121724);
      }
      .imageLoader{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 200px;
      }
    }
  }
  .didVideoCardButtons {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 32px;
    border-radius: 0px 0px 4.5px 4.5px;
    background: var(--background-bg-pages, #121724);
    border-top: 1px solid var(--Border-border-light, #6f748380);
    .didVideoCardButtonContainer {
      display: flex;
      width: 100%;
      &.disabled {
        .didVideoCardButton {
          svg {
            path {
              fill: var(--Button-button-disabled);
            }
          }
        }
      }
      .didVideoCardButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        cursor: pointer;
      }
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        border-left: 2px solid var(--Border-border-light, #6f748380);
      }
    }
  }
}
