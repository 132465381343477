.soundRecorder {
  width: 100%;
  
  gap: 24px;
  margin-top: 24px;
  background: var(--background-bg-light, #192033);
  padding: 24px 16px 24px 16px;
  gap: 24px;
  border-radius: 16px;
  border: 1px solid var(--Border-border-light, #6f748380);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.5s ease;
  overflow: hidden;
  &.hidden{
    height: 0px;
    visibility: hidden;
    // display: none;
  }
  &.visible {
  height: 295px;
  }
  .soundRecorderTime {
    //styleName: Body/Body text medium 14;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
  }
  .soundRecordVisualisation {
    height: 150px;
    width: 640px;
  }
  .soundRecorderButtons {
    justify-content: center;
  }
}
