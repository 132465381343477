.videoPlayer {
  .videoPlayerModal{
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background-color: #000000c0;
    display: flex;
    justify-content: center;
    align-items: center;
    .videoPlayerModalBody{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #575757;
      width: 1120px;
      height: 680px;
      position: relative;
      .videoPlayerModalBodyPlayer{
        position: absolute;
        display: flex;
        justify-content: center;
        // align-items: center;
        width: 100%;
        height: 100%;
        video::-webkit-media-controls {
          display:none !important;
        }
      }
      .videoPlayerModalBodyControls {

      }
      .videoPlayerModalBodyControlsContainer{
        position: absolute;
        left: 0px;
        bottom: 20px;
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        z-index: 99999;
        .videoPlayerModalBodyControls{
          height: 100%;
          width: 80%;
          background: #00000050;
          justify-content: space-evenly;
          align-items: center;
          border-radius: 16px;
          color: #FFFFFF;
          display: flex;
          flex-direction: row;
          .videoPlayerModalBodyControlsProgress {
            width: 573px;
            background: #ffffff80;
            border-radius: 3px;
            height: 6px;
            display: flex;
            flex-direction: row;
            .videoPlayerModalBodyControlsProgressBar{
              height: 6px;
              width: 30%;
              background: #FFFFFF;
              border-radius: 3px;
              display: flex;
              flex-direction: row-reverse;
              &:after {
                content: '';
                background-color: #FFFFFF;
                width: 12px;
                height: 12px;
                border-radius: 6px;
                margin-top: -3px;

                // position: static;
              }

            }
          }
        }
      }
    }
  }
}