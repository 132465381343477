.layoutStyle {
  position: relative;
  .leftSlider {
    min-height: 100vh;
    display: flex;
    position: relative;
    background: var(--background-bg-light);
    max-height: 100vh;
    .logoVertical {
        height: 70px;
      display: flex;
      
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .logo_image {
        margin-left: 24px;
        transition: margin-left 0.5s;
        &.collapsed{
          margin-left: 8px;
        }

        img {
          height: 32px;
          width: 32px;
        }
      }
      .logo_title {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 600;
        line-height: 21.94px;
        text-align: left;
        margin-left: 12px;
        flex-grow: 1;
        a{
          color: var(--Text-text-special, #7479F6);
        }

      }
      .logoBtnContainer {
        display: flex;
        align-items: flex-end;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        
        button{
          // color: var(--Icon-icon-secondary, #EAEAEC);
          font-size: 16px;
          width: 28px;
          height: 20px;          
        }

      }
    }
    .logoutbtn_container{
        color: #c0c0c0;
        position: absolute;
        bottom: 16px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 12px;
        margin: 8px 0px 8px 8px;
        cursor: pointer;
        .logoutbtn_title{
            margin-left: 12px;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 500;
            line-height: 14.63px;
            text-align: left;
            color: var(--Text-text-black) ;
        }
    }
    .ant-layout-sider-children{
        width: 100%;
        .ant-menu-item{
            .ant-menu-item-selected{
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                :after{
                    content: '11';
                }
            }
        } 
    }
  }
  .headerStyle {
    justify-content: space-between;
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    height: 70px;
    background: var(--background-bg-light, #FEFEFE);

    .topLeftPanel {
      align-items: center;
      justify-content: flex-start;
      display: flex;
      height: 70px;
    }
    .topRightPanel {
      align-items: center;
      justify-content: flex-end;
      display: flex;
      height: 70px;
      .darkModeSwith {
        margin-right: 16px;
        .toggleAlgorithmBtn{
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 2px solid var(--Icon-icon-normal, #7479F6);;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
    .uncolllapsedBtn {
        width: 64px;
    }
    .breadcrumbs {
        //styleName: Title/Text - Title 2;
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 600;
        line-height: 29.26px;
        text-align: left;
        margin-left: 24px;
        .breadcrumbs_title{
            margin-left: 12px;
        }
        .breadcrumbs_subtitle{
          margin-left: 16px;
          //styleName: Body/Body text medium 14;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          text-align: left;
          color: var(--Text-text-placeholder, #979AA4);


      }
    }
  }
  .contentStyle {
    background: var(--background-bg-pages, #F8F8FA);
    overflow-y: scroll;
    max-height: calc(100vh - 70px);
  }
}