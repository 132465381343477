.chatAudioCard {
    display: flex;
    flex-direction: column;
    height: 91px;
    width: 236px;
    border: 1px solid #dbdbe080;
    border-radius: 18px;
    overflow: hidden;
    align-items: center;
  
    .chatAudioCardTitle {
      display: -webkit-box;
      align-self: center;
      height: 41px;
      //styleName: Title/Text - Title 4;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
      line-height: 17.07px;
      text-align: center;
      overflow: hidden;
      line-clamp: 1;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      // margin: 8px;
      margin-top: 8px;
      padding: 8px;
      color: var(--Text-text-black, #121724);
      span{ 
        margin-left: 10px;
      }
    }
    .chatAudioCardIcons {
      display: flex;
      height: 42px;
      width: 100%;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: row;
      // border-top: 1px solid #dbdbe080;
      border-top: 1px solid var(--Border-border-light, #DBDBE080);

      .chatAudioCardIconBtn {
        background: var(--background-bg-light, #192033);
        display: flex;
        width: 58px;
        height: 100%;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .chatAudioCardIconBtnGap {
        width: 1px;
        height: 26px;
        background: var(--Border-border-light, #6F748380);
      }
    }
  }