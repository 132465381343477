.supportRequestCard{
  display: flex;
  flex-direction: column;
  width: 100%;
  // min-height: 153px;
  padding: 24px 16px 24px 16px;
  gap: 24px;
  border-radius: 16px;
  background: var(--background-bg-light, #FEFEFE);
  &.light{
    .supportRequestCardHeader{
      display: flex;
      flex-direction: row;
      gap: 32px;
      .supportRequestCardHeaderTags{
        color: var(--Text-text-default, #FEFEFE);
        .statusOpenTag{
          color: var(--Text-text-default, #FDFDFD);
        }
        .statusClosedTag{
          color: var(--Text-text-placeholder, #979AA4);
        }
      }
    }
    .supportRequestCardSubject{
      color: var(--Text-text-black, #121724);
  
    }
    .supportRequestCardBody{
      color: var(--Text-text-black, #121724);  
    }
  }
  &.dark{}
  .supportRequestCardHeader{
    display: flex;
    flex-direction: row;
    gap: 32px;
    .supportRequestCardHeaderId{
      display: flex;
      //styleName: Body/Body text medium 14;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      text-align: left;
      color: var(--Text-text-placeholder, #979AA4);

    }
    .supportRequestCardHeaderTags{
      display: flex;
      //styleName: Body/Body text medium 12;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 500;
      line-height: 14.63px;
      text-align: left;
      color: var(--Text-text-default, #FEFEFE);
      .statusOpenTag{
        background: var(--Icon-icon-success, #68B274);
        border: 1px solid var(--Border-border-success, #68B274);
        color: var(--Text-text-default, #FEFEFE);
        border-radius: 6px;
      }
      .statusClosedTag{
        border-radius: 6px;
        color: var(--Text-text-placeholder, #979AA4);
        border: 1px solid var(--Border-border-light, #DBDBE080);
        background: var(--background-bg-pages, #F8F8FA);

      }
    }
  }
  .supportRequestCardSubject{
    display: flex;
    //styleName: Title/Text - Title 4;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.07px;
    text-align: left;
    color: var(--Text-text-black, #121724);

  }
  .supportRequestCardBody{
    display: flex;
    //styleName: Body/Body text medium 14;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: var(--Text-text-black, #121724);
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
    .supportRequestCardBodyText{
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 3;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow-wrap: anywhere;
    }
  }
}
