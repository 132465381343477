.login_page {
  width: 100%;
  height: 100vh;
  .login_layout {
    height: 100vh;
    justify-content: center;
    background: #F8F8FA;
    .login_layout_row{
        //wrap: {false} justify={"center"} align={'middle'} 
        height: calc(100% - 24px - 24px);
        width: 98%;
        justify-items: center;
        overflow: hidden;
        .login_layout_image_col{
            background-image: url("../../../public/images/login.png");
            background-repeat: no-repeat;
            background-size: cover;
            width: auto;
            height: calc(100% - 24px - 24px);
            margin-right: 12px;
            margin-left: 24px;
            margin-top: 24px;
            margin-bottom: 24px;
            border-radius: 24px;
            
        }
        .login_layout_form_col{
          
            background: #FEFEFE;
            border-radius: 24px;
            height: calc(100% - 24px - 24px);
            width:auto;
            margin-right: 24px;
            margin-left: 12px;
            margin-top: 24px;
            margin-bottom: 24px;
            .login_form_header{
                display: flex;
                height: 56px;
                margin: 24px;
                width: calc(100% - 24px - 24px);
                .login_form_header_title{
                    display: flex;
                    flex-grow: 1;
                    margin-left: 12px;
                    //styleName: Title/Text - Title 3;
                    font-family: Montserrat;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 21.94px;
                    text-align: left;
                    color:  #7479F6;

                }
            }

            .login_form {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 356px;
              height: 100%;
              .login_form_head{
                  color: #121724;
                  //styleName: Title/Text - Title 1;
                  font-family: Montserrat;
                  font-size: 28px;
                  font-weight: 600;
                  line-height: 34.13px;
                  text-align: left;
                  display: flex;
                  width: 100%;
                  height: 64px;
                  justify-content: center;
              }
                form{
                    width: 356px;
                    height: 180px;
                    font-family: Montserrat;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 21px;
                        text-align: left;
    
                    input {
                        width: 356px;
                        font-family: Montserrat;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 21px;
                        text-align: left;
    
                    }
                }

            }
        }
    }
  }
  .tgloginContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding-left: 64px;
    padding-bottom: 16px;
  }
  .loaderContainer {
    display: flex;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  .logoContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-left: 64px;
    padding-bottom: 16px;
  }
}
