.vizardCreateProject{
  width: 100%;
    .vizardCreateProjectCard {
      width: 100%;
      background: var(--background-bg-light, #FEFEFE);
      padding: 24px;
      border-radius: 16px;
      .vizardCreateProjectForm {
        gap: 24px;
        .vizardCreateProjectFormLine {
          width: 100%;
          justify-content: space-around;
          gap: 24px;
          .vizardCreateProjectFormInputContainer{
            width: 100%;
            flex-direction: column;
            align-self: self-end;
          }
    
        }
      }
      .vizardCreateProjectUrlFile {
        gap: 24px;
        width: 100%;
        
        justify-content: start;
        .vizardCreateProjectUrl{
          width: 50%;
          max-width: 50%;
          .vizardCreateProjectFile {
            width: 100%;
            .vizardCreateProjectFileUploader{
              width: 100%;
              .ant-upload.ant-upload-select{
                display: flex;
                width: 100%;
                .ant-upload{
                  display: flex;
                  width: 100%;
                  .fileUploadArea{
                    width: 100%;
                    height: 200px;
                    border-radius: 16px;
                    background: var(--background-bg-pages, #F8F8FA);
                    .fileUploadAreaIcon{
                      width: 30%;
                      justify-content: center;
                      align-items: center;
                    }
                    .fileUploadAreaText{
                      width: 70%;
                      justify-content: center;
                      align-items: center;
                      font-family: Montserrat;
                      font-size: 14px;
                      font-weight: 500;
                      line-height: 21px;
                      text-align: left;
                      text-underline-position: from-font;
                      text-decoration-skip-ink: none;
                      .progressBarContainer{
                        display: flex;
                        flex-direction: column;
                      }
                    }
                  }    
                }
              }
            }
          }
        }

      }
      .vizardCreateProjectPreview{
        width: 50%;
        max-width: 50%;
      }
    }
}