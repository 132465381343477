.ant-upload-picture-circle-wrapper{
  .ant-upload-select {
    overflow: hidden;

  }
}
.imageSelector{
  .uploadAvatarButton{
    &.dark{
        color: var(--Text-text-default, #FDFDFD);
    }
  }
}