.imageSelector{
}
.imageSelectorModal{
  .imageSelectorContainer{
    .imageSelectorBodyContainer{
      .imageSelectorBodyImages{
        width: 60%;
        height: 100%;
        flex-direction: column;
        .imageSelectorHeader{
          display: flex;
          justify-content: space-between;
          height: 64px;

          .imageSelectorHeaderLabel{
            width: 60%;
            font-family: Montserrat;
            font-size: 24px;
            font-weight: 600;
            line-height: 29.26px;
            text-align: left;
            margin-left: 24px;

          }
          .imageSelectorHeaderBtn{}
        }
        .imageSelectorBodyImagesList{
          height: 500px;
          overflow: hidden;
          overflow-y: auto;
          flex-wrap: wrap;
          width: 100%;
          align-content: flex-start;
          gap: 24px;
          display: flex;
        }
        .imageSelectorBodyImagesPagination{
          margin-top: 24px;
        }
      }
      .imageSelectorSelectedImage{
        margin-top: 64px;
        margin-left: 24px;
        flex-wrap: wrap;
        width: 30%; 
        height: 100%;
        .selectedImages{
          display: flex;
          gap: 12px;
          .selectedImage{
            display: flex;
            width: 100px;
            height: 100px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
          .selectedSubImages{
            display: flex;
            gap: 12px;
            .selectedSubImage{
              display: flex;
              width: 64px;
              height: 64px;
              background-repeat: no-repeat;
              background-position: center;
              background-size: contain;
            }
          }
        }
      }
    }
  }  
}