.markdownRenderer {
  width: 80%;
  pre {
    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, с 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ поддерживается в IE, Safari, и Firefox 3.1.*/
  }
  p{
    white-space: break-spaces;
  }
  li {
    white-space: normal;
  }
  img{
    max-width: 250px;
  }
}