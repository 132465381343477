.roleTag {
  //styleName: Body/Body text medium 12;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: left;
  color: var(--Text-text-default, #fefefe);
  align-self: center;
  justify-self: center;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 22px;
  padding: 4px 8px 4px 8px;
  gap: 3px;
  border-radius: 6px;
  border: 1px;
  display: flex;

  &.light {
    &.member {
      background: var(--background-bg-pages, #f8f8fa);
      border: 1px solid var(--Border-border-light, #dbdbe080);
      color: var(--Text-text-black, #121724);

    }
    &.admin {
      background: var(--Icon-icon-success, #68b274);
      border: 1px solid var(--Border-border-success, #68b274);
      color: var(--Text-text-default, #FEFEFE);

    }
    &.superadmin {
      border: 1px solid var(--Border-border-active, #7479f6);
      background: var(--Icon-icon-normal, #7479f6);
      color: var(--Text-text-default, #FEFEFE);

    }
  }
  &.dark {
    &.member {
      background: var(--background-bg-pages, #121724);
      border: 1px solid var(--Border-border-light, #6f748380);
    }
    &.admin {
      background: var(--Icon-icon-success, #68b274);
      border: 1px solid var(--Border-border-success, #009e39);
    }
    &.superadmin {
      background: var(--Icon-icon-normal, #7479f6);
      border: 1px solid var(--Border-border-active, #7479f6);
    }
  }
}
