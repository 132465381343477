.logDetailsContainer{
  display: flex;
  max-height: 80vh;
  overflow: hidden;
  overflow-y: scroll;
  .lablel{
    margin-top: 6px;
    margin-bottom: 6px;
    padding-left: 12px;
    align-items: center;
    display: flex;
  }
  .value{
    margin-top: 6px;
    margin-bottom: 6px;
    // min-height: 34px;
    max-height: 160px;
    border-left: 2px solid var(--Border-border-light, #DBDBE080);
    padding-left: 12px;
    align-items: flex-start;
    overflow: hidden;
    overflow-y: scroll;
    display: flex;
  }
  .horizontalspliter{
    height: 1px;
    border: 1px solid var(--Border-border-light, #DBDBE080)
  }
  .resultContainer {
    display: flex;
    margin-top: 12px;
  }
}