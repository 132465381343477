.cardsContainer{
  display: flex;
  position: relative;
  margin: 12px;
  // width: 26rem;
  flex-direction: row;
  flex-wrap: wrap;
  .chatcard{
    display: flex;
    position: relative;
    min-height: 8rem;
    width: 18rem;
    margin: 12px;
    flex-direction: column;
    height: max-content;
    border-radius: 8px;
    padding: 12px;
    background-color: var(--background-bg-light, #FEFEFE);
    border: 1px solid var(--Border-border-light, #DBDBE080);
    .chatListContainer{
      display: flex;
      position: relative;
      flex-direction: column;
      .chatListLogo{
        display: flex;
        height: 10rem;
        justify-content: center;
        align-items: center;

      }
      .chatListTitle{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .chatListText{
        display: -webkit-box;
        position: relative;
        overflow: hidden;
        word-break: break-word;
        // min-width: 12rem;
        height: 5em;
        line-clamp: 3;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        // white-space: nowrap;
        margin-bottom: 32px;
        margin-top: 12px;
      }
      .chatListAddBtn{
        // position: absolute;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        // bottom: 0px;
        // right: 0px;
      }
    }
  }
  // &.dark{
  //   .chatcard{
  //     background-color: #0d0d0d;
  //     border: 1px solid rgb(48, 48, 48);
  //   }
  // }
  // &.light{
  //   .chatcard{
  //     background-color: #ece9e9;
  //     border: 1px solid rgb(48, 48, 48);
  //   }
  // }
}