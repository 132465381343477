.audioSynthesis {

  .audioSynthesisHint{
    //styleName: Body/Body text medium 14;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: var(--Text-text-placeholder, #979AA4);
    margin-top: 8px;
    margin-bottom: 24px;
  }

}