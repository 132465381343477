.audioSynthesisPropertiesCollapse {
  width: 100%;
  margin-bottom: 24px;
  .audioSynthesisPropertiesTitle{
    //styleName: Body/Button/Button text SemiBold 12;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.63px;
    text-align: center;
    display: flex;
  }
  .audioSynthesisProperties {
    width: 100%;
    flex-direction: column;
    gap: 24px;
    .audioSynthesisPropertiesForm {
      width: 100%;
      justify-content: space-around;
      gap: 24px;
      .audioSynthesisPropertiesFormItem {
        width: 30%;
        .ant-select {
          width: 100%;
        }
      }
    }
  }
}
