.statisticsPage {
  .statisticsTitle{
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 21.94px; 
  }
  .statisticsTotal{
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 21.94px;
  }
  .statisticsWeek{
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 21.94px;
  }
  .statisticsMonth{
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.94px;
  }
}