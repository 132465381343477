
.searchBar {
  display: flex;
  margin-bottom: 12px;
  margin-top: 12px;
  justify-content: space-between;
  .searchBarLine{
    align-items: flex-end;
    display: flex;
    .searchBarCell{
      display: flex;
      flex-direction: column;

    }
  }
}
.users_table {
  .ant-table-cell{
    .operations {
      display: flex;
      flex-wrap: nowrap;
      .tableOperation{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 32px;
        padding-left: 12px;
        padding-right: 12px;
        &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
          border-left: 2px solid var(--Border-border-light, #6F748380);
        }
      }
    }
    .table_phonenumber {
      width: 8rem;
      display: -webkit-box;
      overflow-x: hidden;
      overflow: hidden;
      line-clamp: 1;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }

  }
}