.messageUserContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  .messageUser{
    max-width: 75%;
    margin: 12px;
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    flex-direction: column;
    padding: 24px;
    border-radius: 16px;
    background: var(--background-bg-light, #FEFEFE);
    border: 1px solid var(--Border-border-light, #DBDBE080);
    .messageUserHeader{
      display: flex;
      gap: 12px;
      //styleName: Body/Body text medium 12;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 500;
      line-height: 14.63px;
      text-align: left;

    }
    .messasgeBodyContainer{
      display: flex;
      padding-top: 12px;
      .messageText{
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        text-align: left;
        color: var(--Text-text-black, #121724);
        overflow-wrap: anywhere; //
        white-space: pre-line;
      }

    }
  }
}
