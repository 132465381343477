.messageLoaderContainer{
  display: flex;
  align-self: flex-end;
  width: 100%;
  .messageLoader{
    display: flex;
    flex-direction: row;
    max-width: 75%;
    background: var(--background-bg-active, #EAEBFD);
    padding: 24px;
    border-radius: 16px;



    .avatarContainer{
      display: flex;
      align-items: flex-start;
      margin-right: 16px;
    }
    .messasgeBodyContainer{
      display: flex;
      .messageBody{
        display: flex;
        flex-direction: column;
        .dateName{
          display: flex;
          gap: 12px;
        }
        .messageText{
          display: flex;
          padding-top: 4px;
          //styleName: Body/Body text medium 14;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          text-align: left;
          align-items: flex-end;
          gap: 4px;
          color: var(--Text-text-black, #121724);
            @keyframes fadeIn {
              0% { margin-bottom: 2px }
              50% { margin-bottom: 6px }
              100% { margin-bottom: 2px }
          }
          
          span:nth-child(1) {
            opacity: 1;
            animation: fadeIn 1000ms infinite;
          }
          span:nth-child(2) {
            opacity: 1;
            animation: fadeIn 1000ms infinite;
            animation-delay: 250ms;
          }
          span:nth-child(3) {
            opacity: 1;
            animation: fadeIn 1000ms infinite;
            animation-delay: 500ms;
          }
          .loaderDot {
            width: 5px;
            height: 5px;
            border-radius: 3px;
            border: 1px solid var(--Icon-icon-disabled, #C4C4C8);
            background: var(--Icon-icon-disabled, #C4C4C8);
          }
        }
      }
    }
  }
}

// .message {
//   display: flex;
//   flex-direction: row;
//   .avatar{
//     display: flex;
//     align-items: flex-end;
//     justify-content: flex-end;
//   }
//   .messageTextContainer{
//     display: flex;
//     min-width: 300px;
//     align-items: flex-end;
//     flex-direction: row;
//     justify-content: flex-end;
//     .corner {
//       display: flex;
//       width: 32px;
//       height: 32px;
//       background-color: rgb(117, 135, 156);
//       margin-bottom: 16px;
//     }
//     .messageText {
//       display: flex;
//       position: relative;
//       background-color: rgb(117, 135, 156);
//       margin-bottom: 16px;
//       //padding: 12px;
//       padding-right: 4em;
//       border-top-left-radius: 12px;
//       border-top-right-radius: 12px;
//       border-bottom-right-radius: 12px;
//       white-space: pre-line;
//       --r: 1em;  /* the radius */
//       --t: 1.5em; /* the size of the tail */
//       width: 100%;
//       // max-width: 800px;
//       // padding: 1em;
//       padding-left: 1em;
//       padding-top: 1em;
//       padding-bottom: 1em;
//       border-inline: var(--t) solid #0000;
//       border-radius: calc(var(--r) + var(--t))/var(--r);
//       mask: 
//         radial-gradient(100% 100% at var(--_p) 0,#0000 99%,#000 102%) 
//           var(--_p) 100%/var(--t) var(--t) no-repeat,
//         linear-gradient(#000 0 0) padding-box;
//       // background: linear-gradient(135deg,#FE6D00,#1384C5) border-box;
//       color: #fff;
//       &.left {
//         --_p: 0;
//         border-bottom-left-radius: 0 0;
//         place-self: start;
//       }
//       &.right {
//         --_p: 100%;
//         border-bottom-right-radius: 0 0;
//         place-self: end;
//       }
//       .timeContainer{
//         display: flex;
//         position: absolute;
//         bottom: 4px;
//         right: 8px;
//       }
//     }
//   }
// }
// // .bubble {
// // }

// // body {
// //   margin: 0;
// //   min-height: 100vh;
// //   display: grid;
// //   place-content: center;
// //   gap: 20px;
// //   font-family: system-ui, sans-serif;
// //   font-size: 20px;
// // }