.imagePreview{
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  .imagePreviewImage{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    aspect-ratio: 1 / 1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.fullImage{
  
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px;
}