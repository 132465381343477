.audioRecognitionFile {
  width: 100%;
  height: 153px;
  padding: 24px 16px 24px 16px;
  gap: 24px;
  border-radius: 16px;
  border: 1px solid var(--Border-border-light, #6f748380);
  background: var(--background-bg-light, #192033);

  .audioRecognitionFileLabel {
    //styleName: Body/Body text medium 14;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: var(--Text-text-placeholder, #979aa4);
  }
  .audioRecognitionFileProperties {
    gap: 24px;
  }
}
.audioRecognitionFileCard{
  .fileNameCard{
    
    height: 40px;
    padding: 8px 16px 8px 16px;
    background: var(--background-bg-active, #EAEBFD);
    border-radius: 8px;

  }
}
