.userLogs{
  .userLogsTitle{
    //styleName: Title/Text - Title 3;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.94px;
    text-align: left;

    .userLogsTitleUser {
      padding-left: 8px;
      color: var(--Text-text-special, #7479F6);
    }


  }
}