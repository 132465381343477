// .messageList{
//   // height: calc(100% - 121px);
//   max-height: calc(100% - 121px);
//   overflow-y: auto;
  
  
//   border: 1px solid gray;
  
// }
.sendMessageContainer {
  width: 100%;
  position: relative;
  border: 1px solid var(--Border-border-light);
  border-radius: 16px;
  overflow: hidden;
  // background: var(--background-bg-active, #192033);
  background: var(--background-bg-light, #FEFEFE);
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  padding: 24px;
  gap: 24px;
  border-radius: 16px;
  height: 140px;
  .sendMessageForm{
    display: flex;
    width: 100%;
    textarea {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      text-align: left;
      resize: none;
      // min-height: 6rem;
      // max-height: 10rem;
      &:focus{
        border: none;
        box-shadow: none;
      }

    }
  }
  .sendMessageBtn{
    // position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    // right: 24px;
    // bottom: 24px;
    // width: 64px;
    // z-index: 2;
  }
}