.soundPlayer{
  display: flex;
  width: 100%;
  .progressBarContainer{
    width: 100%;
    background: gray;
    &.idle {
      background: transparent;
    } 
  }
    

}