.supportRequestDetails {
  display: flex;
  gap: 24px;
  flex-direction: column;
  .supportRequestDetailsBody {
    display: flex;
    gap: 24px;
    .supportRequestDetailsBodyText {
      overflow-wrap: anywhere;
    }
  }
  &.dark {
    .supportRequestDetailsHeaders {
      color: var(--Text-text-placeholder, #979AA4);
      .statusOpenTag {
        background: var(--Icon-icon-success, #68b274);
        border: 1px solid var(--Border-border-success, #68b274);
        color: var(--Text-text-default, #fefefe);
      }
      .statusClosedTag {
        color: var(--Text-text-placeholder, #979aa4);
        border: 1px solid var(--Border-border-light, #dbdbe080);
        background: var(--background-bg-pages, #f8f8fa);
      }
    }
    .supportRequestDetailsBody {

      .supportRequestDetailsBodySubject {
        color: var(--Text-text-black, #FDFDFD);

      }
      .supportRequestDetailsBodyText {
        color: var(--Text-text-black, #FDFDFD);
      }
    }
  }
  &.light {
    .supportRequestDetailsHeaders {
      color: var(--Text-text-placeholder, #979AA4);

      .statusOpenTag {
        background: var(--Icon-icon-success, #68b274);
        border: 1px solid var(--Border-border-success, #68b274);
        color: var(--Text-text-default, #fefefe);
      }
      .statusClosedTag {
        color: var(--Text-text-placeholder, #979aa4);
        border: 1px solid var(--Border-border-light, #dbdbe080);
        background: var(--background-bg-pages, #f8f8fa);
      }
    }
    .supportRequestDetailsBody {
      .supportRequestDetailsBodySubject {
        color: var(--Text-text-black, #121724);

      }
      .supportRequestDetailsBodyText {
        color: var(--Text-text-black, #121724);
      }
    }
  }

  .supportRequestDetailsHeaders {
    display: flex;
    gap: 12px;
    .statusOpenTag {
      background: var(--Icon-icon-success, #68b274);
      border: 1px solid var(--Border-border-success, #68b274);
      color: var(--Text-text-default, #fefefe);
      border-radius: 6px;
    }
    .statusClosedTag {
      border-radius: 6px;
      color: var(--Text-text-placeholder, #979aa4);
      border: 1px solid var(--Border-border-light, #dbdbe080);
      background: var(--background-bg-pages, #f8f8fa);
    }
  }

  .supportRequestDetailsSubject {
    display: flex;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.07px;
    text-align: left;
    gap: 24px;
    flex-direction: column;
    .supportRequestCardHeaderTags{
      display: flex;
      gap: 24px;
    }
  }
  .supportRequestDetailsBody {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 80%;
    padding: 24px;
    border: 1px solid var(--Border-border-light);
    border-radius: 12px;
    .supportRequestDetailsBodyUser{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;
    }
    .supportRequestDetailsUser {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    .supportRequestDetailsBodyText {
      display: flex;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      text-align: left;
      overflow-wrap: anywhere;
    }
  }
}
