.chatMessageImageCard {
    display: flex;
    flex-direction: column;
    height: 263px;
    width: 236px;
    border: 1px solid #dbdbe080;
    border-radius: 6px;
    overflow: hidden;
    align-items: center;
  
    .chatMessageImageCardImage {
      display: flex;
      width: 236px;
      height: 200px;
      overflow: hidden;
      cursor: pointer;
      .imageLoader{
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
      }

    }
    .chatMessageImageCardTitle {
      display: -webkit-box;
      align-self: start;
      //styleName: Body/Body text medium 12;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 500;
      line-height: 14.63px;
      text-align: left;
      overflow: hidden;
      line-clamp: 1;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      margin: 8px;
      color: var(--Text-text-black, #121724);
    }
    .chatMessageImageCardIcons {
      display: flex;
      height: 42px;
      width: 100%;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: row;
      // border-top: 1px solid #dbdbe080;
      border-top: 1px solid var(--Border-border-light, #DBDBE080);

      .chatMessageImageCardIconBtn {
        background: var(--background-bg-light, #192033);
        display: flex;
        width: 58px;
        height: 100%;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.disabled {
          cursor: inherit;
          opacity: 0.3;
        }
      }
      .chatMessageImageCardIconBtnGap {
        width: 1px;
        height: 26px;
        background: var(--Border-border-light, #6F748380);
      }
    }
  }