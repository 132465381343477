.chatbotChatPage{
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;

  .messageList{
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
  }
}