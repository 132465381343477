.didVideoPlayer {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  // background-color: #000000c0;
  background: #2D2D2DA6;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  
  .videoPlayerModalDid {
    width: 856;
    // height: 848;
    position: relative;

    .videoPlayerModalBody {
      background: var(--background-bg-light, #192033);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      // margin-left: 16px;
      // margin-right: 16px;
      border-radius: 16px;
      padding-right: 32px;
      padding-left: 32px;
      .videoPlayerModalBodyHeader {
        display: flex;
        height: 56px;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        padding-left: 24px;
        padding-right: 24px;
        border-bottom: 2px solid var(--Border-border-light, #6f748380);
        gap: 8px;
        span {
          font-family: Montserrat;
          font-weight: 500;
          font-size: 12px;
          line-height: 14.63px;
          letter-spacing: 0%;
          color: var(--Text-text-black, #fdfdfd);
          background: var(--background-bg-pages, #121724);
          border: 1px solid var(--Border-border-light, #6f748380);

          height: 22;
          border-radius: 6px;
          gap: 3px;
          padding-top: 4px;
          padding-right: 8px;
          padding-bottom: 4px;
          padding-left: 8px;
        }
        .videoPlayerModalBodyHeaderCloseBtn{
          position: absolute;
          right: 0px;
          width: 32px;
          height: 32px;
          cursor: pointer;
          margin-right: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .videoPlayerModalDidTitle {
        display: flex;
        height: 33px;
        font-family: Montserrat;
        font-weight: 600;
        font-size: 14px;
        line-height: 17.07px;
        letter-spacing: 0%;
      }
      .videoPlayerModalDidContent {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        // margin: 16px;
        .videoPlayerModalDidContentLine {
          display: flex;
          justify-content: center;
          gap: 12px;
          font-family: Montserrat;
          font-weight: 600;
          font-size: 14px;
          line-height: 17.07px;
          letter-spacing: 0%;

          span:nth-child(1){
            text-wrap: nowrap;
          }
          .textField {
            max-height: 64px;
            overflow: hidden;
            overflow-y: scroll;
          }
        }
      }
      .videoPlayerModalDidButtons {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 32px;
        margin-bottom: 24px;
        margin-top: 16px;
        
        border-radius: 8px;
        background: var(--background-bg-pages, #121724);
        
        .didVideoCardButtonContainer {
          display: flex;
          width: 100%;
          &.disabled {
            .didVideoCardButton {
              svg {
                path {
                  fill: var(--Button-button-disabled);
                }
              }
            }
          }
          .didVideoCardButton {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            cursor: pointer;
          }
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            border-left: 2px solid var(--Border-border-light, #6f748380);
          }
        }
      }

      .videoPlayerModalBodyPlayer {
        position: relative;
        display: flex;
        justify-content: center;
        // align-items: center;
        width: 782px;
        height: 440px;
        margin: 16px;
        background-color: #fdfdfd;
        video::-webkit-media-controls {
          display: none !important;
        }
        .videoPlayerModalBodyControlsContainer {
          position: absolute;
          left: 0px;
          bottom: 20px;
          width: 100%;
          height: 80px;
          display: flex;
          justify-content: center;
          z-index: 99999;
          .videoPlayerModalBodyControls {
            height: 100%;
            width: 80%;
            // background: #00000050;
            background: #2D2D2DA6;

            justify-content: space-evenly;
            align-items: center;
            border-radius: 16px;
            color: #ffffff;
            display: flex;
            flex-direction: row;
            padding-left: 24px;
            padding-right: 24px;
            gap: 12px;
            .videoPlayerModalBodyPlayBtn {
              margin-right: 24px;
            }
            .videoPlayerModalBodyCurrentTime {
              margin-right: 8px;
            }
            .videoPlayerModalBodyDurationTime {
              margin-left: 8px;
            }
            .videoPlayerModalBodyControlsProgress {
              width: 573px;
              background: #ffffff80;
              border-radius: 3px;
              height: 6px;
              display: flex;
              flex-direction: row;
              .videoPlayerModalBodyControlsProgressBar {
                height: 6px;
                width: 30%;
                background: #ffffff;
                border-radius: 3px;
                display: flex;
                flex-direction: row-reverse;
                &:after {
                  content: '';
                  background-color: #ffffff;
                  width: 12px;
                  height: 12px;
                  border-radius: 6px;
                  margin-top: -3px;

                  // position: static;
                }
              }
            }
          }
        }
      }
      .videoPlayerModalBodyControls {
      }
    }
  }
}
