.generationsLogContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .generationsLogSearchBar {
    display: flex;
    .generationsLogSearchBarLine {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      gap: 12px;
      .generationsLogSearchBarCell {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .generationsLogTable {
    .promptContainer {
      width: 8rem;
      display: -webkit-box;
      overflow-x: hidden;
      overflow: hidden;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }

    &.dark {
      table {
        -webkit-appearance: none;
        -moz-appearance: none;

        td {
          color: rgb(177, 182, 185);
        }
      }
    }
  }
}
