.generationImagesMainSettingsBody{
  display: flex;
  width:100%;
  flex-direction: row;

  .titleSelect{
    color: var(--Text-text-placeholder)
  }
  .generationImagesMainSettingContainer{
      display: flex;
      width: 33%;
      margin-right: 10px;
      flex-direction: column;
  }
}