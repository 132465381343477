
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  ::-webkit-scrollbar {
    width: 8px;
    height: 10px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--Border-border-light);
    border: 0px none #ffffff;
    border-radius: 50px;
    height: 30px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: var(--Border-border-dark);
  }
  ::-webkit-scrollbar-thumb:active {
    background: var(--Border-border-active);
  }
  ::-webkit-scrollbar-track {
    background: var(--background-bg-light);
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: var(--background-bg-light);
  }
  ::-webkit-scrollbar-track:active {
    background: var(--background-bg-active);
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
  background: var(--background-bg-pages, #F8F8FA);

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root{

  .light_theme{
    --background-bg-light: #FEFEFE;
    --background-bg-pages: #F8F8FA;
    --background-bg-hint: #1E283ECC;
    --background-bg-active: #EAEBFD;
    --background-bg-success: #E9F2EA;
    --background-bg-error: #F9EAEA;  
    --Border-border-light: #DBDBE080;
    --Border-border-dark: #B7B9BF;
    --Border-border-active: #7479F6;
    --Border-border-success: #68B274;
    --Border-border-error: #D83030;    
    --Text-text-default: #FEFEFE;
    --Text-text-black: #121724;
    --Text-text-white: #FEFEFE;
    --Text-text-placeholder: #979AA4;
    --Text-text-special: #7479F6;
    --Text-text-success: #009E39;
    --Text-text-error: #D83030;
    --Paranja-paranja-color: #1E283E33;
    --Button-button-default: #F3F3F7;
    --Button-button-secondary: #2B3858;
    --Button-button-active: #7479F6;
    --Button-button-hover: #9A9DF8;
    --Button-button-danger: #D83030;
    --Button-button-danger-hover: #DF7171;
    --Button-button-disabled: #DBDBE0;
    --Icon-icon-default: #FEFEFE;
    --Icon-icon-secondary: #2B3858;
    --Icon-icon-normal: #7479F6;
    --Icon-icon-success: #68B274;
    --Icon-icon-error: #D83030;
    --Icon-icon-disabled: #C4C4C8;
    --Link-link-fill-dark: #3844F4;
    --Link-link-fill-light: #7479F6;
    --Link-link-disabled: #C4C4C8;
    --Table-table-item: #121724;
    --Table-table-bg: #F0F0F5;
    --Table-table-item-active: #F3F3FE;
    --Table-table-overlay-disabled: #DBDBE0;
  
  }
  
  .dark_theme {
    --background-bg-pages: #121724;
    --background-bg-light: #192033;
    --background-bg-hint: #1E283ECC;
    --background-bg-active: #2B3858;
    --background-bg-success: #007028;
    --background-bg-error: #992222;
    --Border-border-light: #6F748380;
    --Border-border-dark: #6F7483;
    --Border-border-active: #7479F6;
    --Border-border-success: #009E39;    
    --Border-border-error: #B02727;
    --Text-text-default: #FDFDFD;
    --Text-text-black: #FDFDFD;
    --Text-text-white: #FEFEFE;
    --Text-text-placeholder: #979AA4;
    --Text-text-special: #7479F6;
    --Text-text-success: #009E39;
    --Text-text-error: #DF7171;
    --Paranja-paranja-color: #1E283E33;
    --Button-button-default: #232E48;
    --Button-button-secondary: #2B3858;
    --Button-button-active: #7479F6;
    --Button-button-hover: #9A9DF8;
    --Button-button-danger: #DF7171;
    --Button-button-danger-hover: #E69898;
    --Button-button-disabled: #6F7483;
  
    --Icon-icon-default: #FDFDFD;
    --Icon-icon-secondary: #EAEAEC;
    --Icon-icon-normal: #7479F6;
    --Icon-icon-success: #68B274;
    --Icon-icon-error: #DF7171;
    --Icon-icon-disabled: #8B8B8E;
  
    --Link-link-fill-dark: #7479F6;
    --Link-link-fill-light: #B9BBFA;
    --Link-link-disabled: #8B8B8E;
    --Table-table-item: #FDFDFD;
    --Table-table-bg: #192033;
    --Table-table-item-active: #232E48;
    --Table-table-overlay-disabled: #626264;
  
  }
}
// .ant-table {
//   background: var(--Table-table-bg)
// }
// .ant-table .ant-table-thead .ant-table-cell {
//   // color: var(--Table-table-item);
//   color: var(--Text-text-placeholder, #979AA4);
//   background: var(--background-bg-light);
// }
// .ant-table .ant-table-tbody .ant-table-cell {
//   // color: var(--Table-table-item);
//   color: var(--Text-text-placeholder, #979AA4);
//   background: var(--background-bg-light);
// }
.ant-card{
  background: var(--background-bg-light, #192033);
}

.ant-table-container{
  .ant-table-content{
    .ant-table-thead{
      .ant-table-cell{
        font-weight: 600;
      }
    }
    .ant-table-tbody{
      .ant-table-cell{
        color: var(--Text-text-black);
      }
    }
  }
}

.customIcon {
  // width: 24px;
  // height: 24px;

  &.dark {
      path {        
        fill: var(--Icon-icon-default, #FEFEFE);
      }
      &.primary {
        path {        
          fill: var(--Icon-icon-secondary, #EAEAEC);
        }
      }
      &:hover {
        path {        
          fill: var(--Icon-icon-normal, #7479F6);
        }
      }
  }
  &.light {
      path {
        fill: var(--Icon-icon-secondary, #2B3858);
      }
      &.primary {
        path {
          fill: var(--Icon-icon-default, #FEFEFE);

        }
      }
      &:hover {
        path {        
          fill: var(--Icon-icon-normal, #7479F6);
        }
      }
  }

  &.selected {
    &.dark {
      path {        
        fill: var(--Icon-icon-normal, #7479F6);
      }
    }
    &.light {
        path {
          fill: var(--Icon-icon-normal, #7479F6);

        }
    }  
  }

  }