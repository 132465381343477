.supportCreateRequestModal{
  width: 572px;
  border-radius: 16px;
  padding-bottom: 0px;
  
  .ant-modal-content{
    border-radius: 16px;
    .ant-modal-body{
      padding-top: 24px;
    }
  }
  .ant-modal-header{
  }
  .supportCreateRequest{
    .ant-form-item-label{
      //styleName: Body/Body text medium 10;
      font-family: Montserrat;
      font-size: 10px;
      font-weight: 500;
      line-height: 12.19px;
      text-align: left;
    }
  }
  &.dark{
    .ant-select {
      background: var(--background-bg-light, #192033);

    }
  }
  &.light{
    
  }
}