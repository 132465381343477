.logDetailsContainer{
  display: flex;
  .lablel{
    margin-top: 6px;
    margin-bottom: 6px;
    height: 34px;
    padding-left: 12px;
    align-items: center;
    display: flex;
  }
  .value{
    margin-top: 6px;
    margin-bottom: 6px;
    height: 34px;
    border-left: 2px solid var(--Border-border-light, #DBDBE080);
    padding-left: 12px;
    align-items: center;
    display: flex;
  }
  .horizontalspliter{
    height: 1px;
    border: 1px solid var(--Border-border-light, #DBDBE080)
  }
}