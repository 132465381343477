.supportRequestDetails {
  &.dark {
    .supportRequestDetailsHeaders {
      color: var(--Text-text-placeholder, #979AA4);
      .statusOpenTag {
        background: var(--Icon-icon-success, #68b274);
        border: 1px solid var(--Border-border-success, #68b274);
        color: var(--Text-text-default, #fefefe);
      }
      .statusClosedTag {
        color: var(--Text-text-placeholder, #979aa4);
        border: 1px solid var(--Border-border-light, #dbdbe080);
        background: var(--background-bg-pages, #f8f8fa);
      }
    }
    .supportRequestDetailsBody {

      .supportRequestDetailsBodySubject {
        color: var(--Text-text-black, #FDFDFD);

      }
      .supportRequestDetailsBodyText {
        color: var(--Text-text-black, #FDFDFD);
      }
    }
  }
  &.light {
    .supportRequestDetailsHeaders {
      color: var(--Text-text-placeholder, #979AA4);

      .statusOpenTag {
        background: var(--Icon-icon-success, #68b274);
        border: 1px solid var(--Border-border-success, #68b274);
        color: var(--Text-text-default, #fefefe);
      }
      .statusClosedTag {
        color: var(--Text-text-placeholder, #979aa4);
        border: 1px solid var(--Border-border-light, #dbdbe080);
        background: var(--background-bg-pages, #f8f8fa);
      }
    }
    .supportRequestDetailsBody {
      .supportRequestDetailsBodySubject {
        color: var(--Text-text-black, #121724);

      }
      .supportRequestDetailsBodyText {
        color: var(--Text-text-black, #121724);
      }
    }
  }

  .supportRequestDetailsHeaders {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .statusOpenTag {
      background: var(--Icon-icon-success, #68b274);
      border: 1px solid var(--Border-border-success, #68b274);
      color: var(--Text-text-default, #fefefe);
      border-radius: 6px;
    }
    .statusClosedTag {
      border-radius: 6px;
      color: var(--Text-text-placeholder, #979aa4);
      border: 1px solid var(--Border-border-light, #dbdbe080);
      background: var(--background-bg-pages, #f8f8fa);
    }
  }
  .supportRequestDetailsBody {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .supportRequestDetailsBodySubject {
      display: flex;
      display: flex;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
      line-height: 17.07px;
      text-align: left;
      

    }
    .supportRequestDetailsBodyText {
      display: flex;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      text-align: left;
      overflow-wrap: anywhere;
    }
  }
}
