.lkVideoDidLibraryModal {
  height: 80vh;
  gap: 32px;
  border-radius: 16px;
  background: var(--background-bg-light, #192033);
  opacity: 1;
  overflow: hidden;
  .ant-modal-content {
    box-shadow: none;
  }
  .lkVideoDidLibraryTitle{
    display: grid;
    height: 54px;
    color: var(--Text-text-special, #7479f6);
    //styleName: Title/Text - Title 3;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.94px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    align-items: center;
    border-bottom: 2px solid var(--Border-border-light, #6f748380);
  }
  .lkVideoDidLibrary {
    width: 740px;
    display: grid;
    overflow: hidden;
    height: calc(80vh - 140px);
    overflow-y: auto;
    padding-right: 4px;
    padding-top: 8px;
    .lkVideoDidLibraryAvatarsContainer {
      .lkVideoDidLibraryAvatarsLoader {
        
      }
      .lkVideoDidLibraryAvatars {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        row-gap: 18px;
        column-gap: 16px;
        .lkVideoDidLibraryAvatar {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border: 1px solid var(--Border-border-light, #6f748380);
          border-radius: 6px;
          background: var(--background-bg-light, #192033);
          &.selected {
            border: 2px solid var(--Border-border-active, #7479f6);
          }
          .lkVideoDidLibraryAvatarImgContainer {
            width: 120px;
            height: 140px;
            border-radius: 6px;
            background-color: #fdfdfd;
            margin-top: 16px;
            .lkVideoDidLibraryAvatarImg {
              background-repeat: no-repeat;
              background-position-x: center;
              background-position-y: bottom;
              background-size: contain;
              width: 120px;
              height: 140px;
            }
          }
          .lkVideoDidLibraryAvatarTitle {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
            margin-bottom: 16px;
          }
        }
      }
    }
    .lkVideoDidLibraryVoicesContainer {
      display: grid;
      row-gap: 16px;
      .lkVideoDidLibraryVoicesTitle {
        display: grid;
        height: 54px;
        color: var(--Text-text-special, #7479f6);
        //styleName: Title/Text - Title 3;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 600;
        line-height: 21.94px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        align-items: center;
        border-bottom: 2px solid var(--Border-border-light, #6f748380);
      }
      .lkVideoDidLibraryVoicesAlert {
        //styleName: Body/Body text medium 12;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        line-height: 14.63px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: var(--Text-text-placeholder, #979aa4);
      }
      .lkVideoDidLibraryVoicesLoader {
      }

      .lkVideoDidLibraryVoices {
        display: grid;
        // grid-template-columns: 150px 150px 150px 150px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        row-gap: 16px;
        column-gap: 16px;
        .lkVideoDidLibraryVoice {
          display: grid;
          grid-template-columns: 3fr 1fr;
          gap: 4px;
//           width: Fill (117px)px;
// height: Fixed (40px)px;
// padding: 6px 15px 6px 15px;
// gap: 10px;


          &.selected {
            .lkVideoDidLibraryVoiceTitle {
              background: var(--Button-button-hover, #9A9DF8);
            }
          }
          .lkVideoDidLibraryVoiceTitle {
            //styleName: Body/Body text medium 14;
            cursor: pointer;
            padding: 6px 15px 6px 15px;
            gap: 10px;
            border-radius: 6px;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: var(--Text-text-black, #fdfdfd);
            background: var(--background-bg-active, #2b3858);
          }
          .lkVideoDidLibraryVoiceAudio {
            border: 1px solid var(--Border-border-active, #7479f6);
            padding: 9px 15px 9px 15px;
            cursor: pointer;
            display: grid;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            position: relative;
            overflow: hidden;
            .soundPlayer {
              position: absolute;
              bottom: 0px;
              left: 1px;
              width: 98%;
            }
          }
        }
      }
    }
  }
}
